import { observer } from 'mobx-react'
import style from './index.module.css'
import MyCourseContent from '../../features/MyCourseContent'
import Curtain from '../../components/Curtain'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppStore } from '../../hooks/useAppStore'
import useConfigurateBackButton from '../../hooks/useConfigurateBackButton'
import MyCoursePageLoader from '../../components/PageLoaders/MyCoursePageLoader'


const MyCoursePage = () => {
    const searchParams = useParams();
    const {id} = searchParams;
    const {getMyCourse, myCourse} = useAppStore();
    useConfigurateBackButton();;

    useEffect(() => {
        if(id) {
            getMyCourse(id)
        }
    }, [id, getMyCourse])

    if(!myCourse) {
        return <MyCoursePageLoader />
    }

    return (
        <div className={style.wrapper}>
            <MyCourseContent />
            <Curtain />
        </div>
    )
}

export default observer(MyCoursePage)