import style from './index.module.css'
import { useAppStore } from '../../hooks/useAppStore'
import DropDownList from '../../components/DropDownList';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useBottonContent } from '../../hooks/useBottomContent';


const DetailCourseContent = () => {
    const {course, buyCourse} = useAppStore();
    const { setProps } = useBottonContent();

    useEffect(() => {
        if(course) {
            if(!course.isBuyed) {
                setProps({
                    callback: () => {
                        buyCourse(course.id)
                    },
                    buttonProps: {
                        title: 'Купить курс',
                        subtitle: `${course.price} ₽`,
                        backgroundColor: 'rgba(183, 136, 229, 1)',
                    },
                    isOpen: true,
                })
            } else {
                setProps({
                    callback: undefined,
                    isOpen: false,
                })
            }
        }

        return () => {
            setProps({
                callback: undefined,
                isOpen: false,
            })
        }
    // eslint-disable-next-line
    }, [course])
    return(
        <div className={style.wrapper}>
            {
                course && (
                    <>
                        <div className={style.title}>{course.title}</div>
                        <div className={style.modulesContainer}>
                            {
                                course.modules.map((module: any, index: number) => (
                                    <div key={module.id} className={style.module}>
                                        <DropDownList
                                            title={module.title}
                                            startedItemsCount={index === 0 ? module.items.length : 0}
                                            items={module.items}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </>
                )
            }
            
        </div>
    )
}

export default observer(DetailCourseContent)