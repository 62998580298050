
import { observer } from 'mobx-react';
import MainPageBanner from '../../components/MainPageBanner';
import MainPageContent from '../../features/MainPageContent';
import style from './index.module.css';
import Curtain from '../../components/Curtain';
import { useEffect } from 'react';
import { useAppStore } from '../../hooks/useAppStore';
import useConfigurateBackButton from '../../hooks/useConfigurateBackButton';

const MainPage = () => {
    const {getAvailableCategories, getMyCourses} = useAppStore();
    useConfigurateBackButton();

    useEffect(() => {
        getAvailableCategories();
        getMyCourses();
    }, [getAvailableCategories, getMyCourses])

    return (
        <div className={style.wrapper}>
            <MainPageBanner />
            <MainPageContent />
            <Curtain />
        </div>
    )
}

export default observer(MainPage);