import style from './index.module.css'

export default function MyCoursePageLoader () {
    return (
        <div className={style.wrapper}>
            <div className={style.cell}></div>
            <div className={style.cell}></div>
            <div className={style.cell}></div>
        </div>
    )
}