import { observer } from 'mobx-react'
import style from './index.module.css'
import LessonContent from '../../features/LessonContent'
import Curtain from '../../components/Curtain'
import useConfigurateBackButton from '../../hooks/useConfigurateBackButton'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppStore } from '../../hooks/useAppStore'
import LessonPageLoader from '../../components/PageLoaders/LessonPageLoader'


const LessonPage = () => {
    const searchParams = useParams();
    const {courseId, lessondId} = searchParams;
    const {getLesson, lesson} = useAppStore();
    useConfigurateBackButton();

    useEffect(() => {
        if(courseId && lessondId) {
            getLesson(courseId, lessondId)
        }
    }, [courseId, lessondId, getLesson])

    if(!lesson || !lesson.id) {
        return <LessonPageLoader />
    }

    return (
        <div className={style.wrapper}>
            <LessonContent />
            <Curtain />
        </div>
    )
}

export default observer(LessonPage)