import style from './index.module.css'
import CourseBanner from "../../components/CourseBanner"
import CourseContent from "../../features/CourseContent"
import { observer } from "mobx-react"
import Curtain from '../../components/Curtain'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppStore } from '../../hooks/useAppStore'
import useConfigurateBackButton from '../../hooks/useConfigurateBackButton'
import CoursePageLoader from '../../components/PageLoaders/CoursePageLoader'

const CoursePage = () => {
    const searchParams = useParams();
    const {id} = searchParams;
    const {getCourse, course} = useAppStore();
    useConfigurateBackButton();


    useEffect(() => {
        if(id) {
            getCourse(id);
        }
    }, [id, getCourse])


    if(!course) {
        return <CoursePageLoader />
    }

    return (
        <div className={style.wrapper}>
            <CourseBanner />
            <CourseContent />
            <Curtain />
        </div>
    )
}

export default observer(CoursePage)