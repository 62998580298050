import { useEffect } from "react"
import { getTg } from "../utils/getTg";
import { useNavigate } from "react-router-dom";

const useConfigurateBackButton = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const {tg} = getTg();
        tg.BackButton.onClick(() => {navigate(-1)})
        tg.BackButton.show();
    }, [navigate])
}

export default useConfigurateBackButton;