import style from './index.module.css'
;
import { observer } from 'mobx-react';


const MainPageBanner = () => {
    return (
        <div
            style={{
                backgroundImage: `url(https://s3.smltlk.ru/public/uploads/image/system/NTY2ZjU3MDdkZDc1LTkwMDAw.png)`,
            }}
            className={style.banner}
        ></div>
    )
}

export default observer(MainPageBanner);