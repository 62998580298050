import { observer } from "mobx-react"
import style from './index.module.css'
import Chip from "../Chip"
import { NavLink } from "react-router-dom"
import { MyCoursesChip } from "../../normalizers/myCoursesNormalize"

interface CompletedCourseProps {
    title: string,
    id: string,
    chips?: Array<MyCoursesChip>,
}

const CompletedCourse = (props: CompletedCourseProps) => {
    const {
        title,
        chips,
        id,
    } = props;

    return (
        <NavLink to={`/myCourse/${id}`} className={style.wrapper}>
            <div className={style.title}>{title}</div>
            {
                chips && (
                        <div className={style.chipList}>
                        {
                            chips.map((chip) => (
                                <div className={style.chipWrapper}>
                                    <Chip {...chip} />
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </NavLink>
    )
}

export default observer(CompletedCourse)