import { SVGIcon } from ".";

export default function VIDEO({fill}: SVGIcon) {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.865722 0.865722C1.4963 0.235143 2.43423 0 3.62245 0H6.37755C7.56577 0 8.5037 0.235143 9.13428 0.865722C9.76486 1.4963 10 2.43423 10 3.62245V6.37755C10 7.56577 9.76486 8.5037 9.13428 9.13428C8.5037 9.76486 7.56577 10 6.37755 10H3.62245C2.43423 10 1.4963 9.76486 0.865722 9.13428C0.235143 8.5037 0 7.56577 0 6.37755V3.62245C0 2.43423 0.235143 1.4963 0.865722 0.865722ZM1.44295 1.44295C1.04037 1.84554 0.816327 2.51475 0.816327 3.62245V6.37755C0.816327 7.48525 1.04037 8.15446 1.44295 8.55705C1.84554 8.95963 2.51475 9.18367 3.62245 9.18367H6.37755C7.48525 9.18367 8.15446 8.95963 8.55705 8.55705C8.95963 8.15446 9.18367 7.48525 9.18367 6.37755V3.62245C9.18367 2.51475 8.95963 1.84554 8.55705 1.44295C8.15446 1.04037 7.48525 0.816327 6.37755 0.816327H3.62245C2.51475 0.816327 1.84554 1.04037 1.44295 1.44295ZM4.27672 3.78445C4.19769 3.82986 4.07685 3.96882 4.07685 4.32023V5.67941C4.07685 6.02774 4.19724 6.16761 4.27733 6.21383C4.35759 6.26014 4.53957 6.29449 4.84242 6.12017C4.84237 6.1202 4.84247 6.12014 4.84242 6.12017L6.01746 5.44085C6.32033 5.26647 6.38182 5.09174 6.38182 4.99982C6.38182 4.9079 6.32095 4.73353 6.01809 4.55916L4.84258 3.87957C4.8425 3.87952 4.84266 3.87961 4.84258 3.87957C4.53877 3.70472 4.35645 3.73862 4.27672 3.78445ZM3.86996 3.07668C4.28957 2.83552 4.7962 2.9109 5.2499 3.17212L6.42541 3.85171C6.42532 3.85166 6.4255 3.85176 6.42541 3.85171C6.88005 4.11356 7.19815 4.51556 7.19815 4.99982C7.19815 5.48412 6.88027 5.88599 6.42557 6.14784C6.42552 6.14787 6.42563 6.14781 6.42557 6.14784L5.25052 6.82716C4.79582 7.08896 4.28844 7.16271 3.86935 6.92089C3.45009 6.67897 3.26052 6.20354 3.26052 5.67941V4.32023C3.26052 3.7946 3.44963 3.31825 3.86996 3.07668Z"
                //"#C1FFC3"
                fill={fill}
            />
        </svg>
    )
}

