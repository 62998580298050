import { observer } from "mobx-react"
import style from './index.module.css'
import MyCourseCard, { Statuses } from "../../components/MyCourseCard"
import { useAppStore } from "../../hooks/useAppStore"
import DropDownList from "../../components/DropDownList"
import { useEffect } from "react"
import { useCurtainControl } from "../../hooks/useCurtainContol"
import CurtainContent from "../../components/CurtainContents/CurtainContentCompleted"
import { useBottonContent } from "../../hooks/useBottomContent"
import { useNavigate } from "react-router-dom"



const MyCourseContent = () => {
    const {myCourse, completeCourse} = useAppStore();
    const {setProps: setCurtainProps} = useCurtainControl();
    const {setProps: setBottomContentProps} = useBottonContent();
    const navigate = useNavigate();

    useEffect(() => {
        if(myCourse) {
            if(myCourse.status === Statuses.READYTODONE) {
                setBottomContentProps({
                    isOpen: true,
                    callback: () => {
                        completeCourse(myCourse.id);
                        setCurtainProps({
                            children: <CurtainContent
                                    setClose={() => {
                                        setCurtainProps({isOpen: false})
                                    }}
                                />,
                            isOpen: true,
                        })
                    },
                    buttonProps: {
                        title: 'Завершить курс',
                        backgroundColor: 'rgba(241, 190, 18, 1)'
                    }
                })
            }
            if(myCourse.status === Statuses.COMPLETED) {
                setBottomContentProps({
                    isOpen: false,
                    callback: undefined,
                })
            }  
             
            if(myCourse.status === Statuses.INPROGRESS || myCourse.status === Statuses.NEW){
                setBottomContentProps({
                    isOpen: true,
                    callback: () => {
                        navigate(`/lesson/${myCourse.id}/${myCourse.nextLesson}`)
                    },
                    buttonProps: {
                        title: 'Продолжить',
                        backgroundColor: '#B788E5'
                    }
                })
            }
        }

        return () => {
            setBottomContentProps({
                isOpen: false,
            });
            setCurtainProps({isOpen: false, children: null});
        }
    // eslint-disable-next-line
    }, [myCourse?.status])

    if(!myCourse) {
        return null;
    }

    return (
        <div className={style.wrapper}>
            <div className={style.header}>
                <MyCourseCard
                    title={myCourse.title}
                    tagStatus={myCourse.tagStatus}
                    progress={myCourse.progress}
                    status={myCourse.status}
                    id={myCourse.id}
                />
            </div>
            <div className={style.content}>
                {
                    myCourse.modules.map((module: any) => (
                        <div className={style.listWrapper}>
                            <DropDownList
                                {...module}
                            />
                        </div>
                    ))
                }
            </div>    
        </div>
    )
}

export default observer(MyCourseContent)