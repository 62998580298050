import style from './index.module.css'
import DetailCourseContent from "../../features/DetailCourseContent"
import { observer } from 'mobx-react'
import Curtain from '../../components/Curtain'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppStore } from '../../hooks/useAppStore'
import useConfigurateBackButton from '../../hooks/useConfigurateBackButton'


const DetailCoursePage = () => {

    const searchParams = useParams();
    const {id} = searchParams;
    const {getCourse} = useAppStore();
    useConfigurateBackButton();

    useEffect(() => {
        if(id) {
            getCourse(id);
        }
    }, [id, getCourse])

    return (
        <div className={style.wrapper}>
            <DetailCourseContent />
            <Curtain />
        </div>
    )
}

export default observer(DetailCoursePage)