import { observer } from "mobx-react";
import CategoryBanner from "../../components/CategoryBanner";
import CategoryContent from "../../features/CategoryContent";
import style from './index.module.css'
import Curtain from "../../components/Curtain";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppStore } from "../../hooks/useAppStore";
import useConfigurateBackButton from "../../hooks/useConfigurateBackButton";
import CategoryPageLoader from "../../components/PageLoaders/CategoryPageLoader";

const CategoryPage = () => {
    const searchParams = useParams();
    const {id} = searchParams;
    const {getCategory, category} = useAppStore();
    useConfigurateBackButton();

    useEffect(() => {
        if(id) {
            getCategory(id);
        }
    }, [id, getCategory])

    if(!category || !category.id) {
        return <CategoryPageLoader />
    }

    return (
        <div className={style.wrapper}>
            <CategoryBanner />
            <CategoryContent />
            <Curtain />
        </div>
    )
}

export default observer(CategoryPage);