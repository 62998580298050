import style from './index.module.css'
import cn from 'classnames'

export default function CategoryPageLoader () {
    return (
        <div className={style.wrapper}>
            <div className={cn(style.banner, style.animation)}></div>
            <div className={style.section}>
                <div className={cn(style.title, style.animation)}></div>
                <div className={style.slider}>
                    <div className={cn(style.item, style.animation)}></div>
                    <div className={cn(style.item, style.animation)}></div>
                </div>
            </div>
        </div>
    )
}