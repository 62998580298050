import { observer } from "mobx-react"
import YouTube, {YouTubeProps} from "react-youtube"
import { useState } from "react"

import style from './index.module.css'

interface LessonHeaderProps {
    videoId: string,
    onPlay: () => void,
}

const LessonHeader = ({videoId, onPlay}: LessonHeaderProps) => {
    const [currentInterval, setCurrentInterval] = useState<number | null>() 

    const YouTubeProps: YouTubeProps['opts'] = {
        width: window.innerWidth,
        height: 200,
    }
    return (
        <div className={style.wrapper}>
            <YouTube
                videoId={videoId}
                opts={YouTubeProps}
                onPlay={(e: any) => {
                    onPlay();
                }}
                onPause={() => {
                    if(currentInterval) {
                        clearInterval(currentInterval)
                    }
                    setCurrentInterval(null)
                }}
            />
        </div>
    )
}

export default observer(LessonHeader)