import { MyCourseResponseType } from "../api/types";
import { Statuses } from "../components/MyCourseCard";
import { TagsTypes } from "../components/Tag";
import { CourseModuleType } from "./detailCourseNormalize";

export type MyCourseType = {
    status: Statuses,
    title: string,
    tagStatus: TagsTypes,
    progress: {
        completed: number,
        total: number,
    },
    id: string,
    recomendations?: any,
    modules: Array<CourseModuleType>,
    nextLesson?: string,
}


export default function MyCourseNormalize(data: MyCourseResponseType | []): MyCourseType | undefined {
    if(Array.isArray(data)) {
        return undefined;
    }
    const {modules, id} = data;
    let tagStatus = TagsTypes.NEW;
    let status = Statuses.NEW

    switch(data.status) {
        case 'todo':
            tagStatus = TagsTypes.NEW;
            status = Statuses.NEW;
            break;
        case 'in_progress':
            tagStatus = TagsTypes.INPROGRESS;
            status = Statuses.INPROGRESS;
            break;

        case 'ready_to_done':
            tagStatus = TagsTypes.INPROGRESS;
            status = Statuses.READYTODONE;
            break;

        case 'done':
            tagStatus = TagsTypes.COMPLETED;
            status = Statuses.COMPLETED;
            break;
    }

    const innerModules = modules.sort((a, b) => a.position - b.position).map((module, moduleIndex) => {
        const filteredItems = module.lessons.sort((a, b) => a.position - b.position)
        return {
            title: module.title,
            subtitle: `Модуль ${moduleIndex + 1}`,
            tagStatus: module.status === 'todo' ? TagsTypes.NEW : module.status === 'in_progress' ? TagsTypes.INPROGRESS : TagsTypes.COMPLETED,
            items: filteredItems.map((lesson) => ({
                title: lesson.title,
                path: `lesson/${id}`,
                isDisabled: false,
                complete: lesson.status === 'done',
                id: lesson.id,
            }))
        }
    })

    return {
        status,
        title: data.title,
        tagStatus,
        progress: data.progress,
        id,
        modules: innerModules,
        nextLesson: data.next_lesson,
    }
}