import style from './index.module.css'
import { observer } from 'mobx-react';

const CurtainLessonContent = (props: any) => {
    const {setClose, description, buttonProps} = props;
    return (
        <div className={style.wrapper}>
            <div className={style.title}>
                <span className={style.titleText}>Об уроке</span>
                {
                    setClose && (
                        <div
                            className={style.exitButton}
                            onClick={() => {
                                setClose()
                            }}
                        ></div>
                    )
                }
            </div>
            <div className={style.description}>{description}</div>
            {
                buttonProps && (
                    <div
                        onClick={buttonProps.callback}
                        className={style.button}
                        style={{
                            backgroundColor: buttonProps.backgroundColor
                        }}
                    >
                        {buttonProps.title}
                    </div>
                )
            }
        </div>
    );
}

export default observer(CurtainLessonContent);
