import { LessonResponseType } from "../api/types";
import { Statuses } from "../components/MyCourseCard";


export type LessonRecomendation = {
    title: string,
    id: string,
    image: string,
    url: string,
    preview_text: string,
}

export type LessonType = {
    description: string,
    title: string,
    id: string,
    nextLesson?: string,
    status: Statuses,
    recomendations: Array<LessonRecomendation>,
    url: string,
    preview?: string | null,
}

export default function LessonNormalize(data: LessonResponseType): LessonType {
    const {lesson_items} = data;

    let status = Statuses.NEW;

    if(!data.was_first_viewing && data.status === 'todo') {
        status = Statuses.NEW;
    }

    if(data.was_first_viewing && data.status === 'todo') {
        status = Statuses.FAKESTARTED;
    }

    if(data.was_first_viewing && data.status === 'in_progress') {
        status = Statuses.INPROGRESS;
    }

    if(data.was_first_viewing && data.status === 'done') {
        status = Statuses.COMPLETED;
    }

    const recomendations = (lesson_items || []).sort((a, b) => a.position - b.position).map((item, index) => ({...item, preview_text: item.description, id: `${index}`}));

    return {
        id: data.id,
        title: data.title,
        description: data.description,
        recomendations,
        status,
        nextLesson: data.next_lesson,
        url: data.video?.url,
        preview: data.video?.preview,
    }
}