import style from './index.module.css';
import cn from 'classnames'

export default function LessonPageLoader () {
    return (
        <div className={style.wrapper}>
            <div className={cn(style.video, style.animation)}></div>
            <div className={cn(style.title, style.animation)}></div>
            <div className={cn(style.more, style.animation)}></div>
            <div className={cn(style.subtitle, style.animation)}></div>
            <div className={style.items}>
                <div className={cn(style.item, style.animation)}></div>
                <div className={cn(style.item, style.animation)}></div>
            </div>
        </div>
    )
}